const Constants = {
    // url
    // DEFAULT_URL: "http://localhost:8080/api", //unused
    // DEFAULT_URL: "https://app6.nimsoft.xyz/api", // server dev postgresq
    
    // DEFAULT_URL: "http://localhost:8080",
    // DEFAULT_URL: "https://test.nimtransport.com/rodyok-api", // server dev oracle
    DEFAULT_URL: "https://app80.nimtransport.com/rodyok-api", // server production oracle

    // date format
    DATE_FORMAT: "DD/MM/YYYY",
    TIME_FORMAT: "HH:mm:ss",
    DATE_FORMAT_FULL: "DD/MM/YYYY HH:mm:ss",
    DATE_FORMAT_SKIP_SEC: "DD/MM/YYYY HH:mm:00",
    DATE_FORMAT_SKIP_MINUTE_SEC: "DD/MM/YYYY HH:00:00",
    DATE_FORMAT_MIDNIGHT: "DD/MM/YYYY 00:00:00",
    DB_FORMAT: "YYYYMMDD",
    DB_TIME_FORMAT: "HHmmss",

    // authen
    AUTHEN_USER: "AUTHEN_USER",
    AUTHEN_EXPIRED_TIME: "AUTHEN_EXPIRED_TIME",
}

export default Constants