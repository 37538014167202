import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Select, Space, Table, Typography, Modal } from 'antd';
import moment from 'moment';
import Constants from 'src/utils/constants';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/stores/store';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import tAssetHeadService from "src/services/tasset/tAssetHeadService";
import { TAssetHeadView } from 'src/domain/master/view/tAssetHeadView';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import reportService from 'src/services/reportService';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import { toThDate, formatTime } from 'src/utils/dateTimeUtils';
import PdfViewPopup from './PdfViewPopup';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import { TTypetruckRef } from 'src/domain/tTypetruckRef';
import tTypetruckRefService from "src/services/reference/tTypetruckRefService";
import { TruckIncomeReportData } from 'src/domain/worksheet/truckIncomeReportData';

const { Option } = Select;

const TruckIncomeReport = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  
  const [assets, setAssets] = useState<TAssetHeadView[]>([]); //001 รถ,002 ตู้,003 แผ่นเหล็ก
  const [ryRefBranchs, setRyRefBranchs] = useState<RyRefbranch[]>([]);  //สาขา
  const [tTypeTruckRefList, setTTypeTruckRefList] = useState<TTypetruckRef[]>([]);  //ประเภทรถ
  const [ryRefBranchSelected, setRyRefBranchSelected] = useState<RyRefbranch>({} as RyRefbranch);
  const [tTypeTruckRefId, setTTypeTruckRefId] = useState("");

  const [truckIncomeReportList, setsetTruckIncomeReportList] = useState<TruckIncomeReportData[]>([]);

  const [visiblePDF, setVisiblePDF] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  
  const [sumTotal2Amt, setSumTotal2Amt] = useState<any>();
  const [fromDateParam, setFromDateParam] = useState<any>();
  const [toDateParam, setToDateParam] = useState<any>();
  const [truckCodeParam, setTruckCodeParam] = useState<any>();
  const [typeTruckParam, setTypeTruckParam] = useState<any>();
  const [branchParam, setBranchParam] = useState<any>();
  

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 10,
      align: "center" as "center",
      render: (value: number, item: TruckIncomeReportData, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "รหัสรถ",
      dataIndex: "numbers",
      key: "numbers",
      width: 70,
    },
    {
      title: "รถ",
      dataIndex: "assetName",
      key: "assetName",
      width: 120,
    },	
    {
      title: "เลขที่ใบจอง",
      dataIndex: "reserveCode",
      key: "reserveCode",
      width: 20,
      align: "center" as "center",
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "worksheetCode",
      key: "worksheetCode",
      width: 20,
      align: "center" as "center",
    },
    {
      title: "วันที่ทำงาน",
      dataIndex: "startDate",
      key: "startDate",
      width: 20,
      align: "center" as "center",
      render: (value: any, item: TruckIncomeReportData) => (<>{toThDate(item.startDate)}</>)
    },
    {
      title: "เวลาเริ่มงาน",
      dataIndex: "startTime",
      key: "startTime",
      width: 20,
      align: "center" as "center",
      render: (value: any, item: TruckIncomeReportData) => (<>{formatTime(item.startTime)}</>)
    },
    {
      title: "วันที่งานเสร็จ",
      dataIndex: "endDate",
      key: "endDate",
      width: 20,
      align: "center" as "center",
      render: (value: any, item: TruckIncomeReportData) => (<>{toThDate(item.endDate)}</>)
    },
    {
      title: "เวลางานเสร็จ",
      dataIndex: "endTime",
      key: "endTime",
      width: 20,
      align: "center" as "center",
      render: (value: any, item: TruckIncomeReportData) => (<>{formatTime(item.endTime)}</>)
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "worksheetStatus",
      key: "worksheetStatus",
      width: 60,
      align: "right" as "right",
      render: (value: any, item: TruckIncomeReportData) => {
        return <span>{formatNumber(item.total2Amt)}</span>;
      },
    },
    {
      title: "ลูกค้า",
      dataIndex: "custName",
      key: "custName",
      width: 190,
    },
    {
      title: "รายการ",
      dataIndex: "remark",
      key: "remark",
      width: 190,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "note",
      key: "note",
      width: 80,
    },
  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });

    (async () => {
      const refbranchRes = await ryRefbranchService.getRyRefbranchList();
      const assetGroupRes = await tTypetruckRefService.getTTypetruckRefList();
    
      setRyRefBranchs(refbranchRes.data);
      setTTypeTruckRefList(assetGroupRes.data);
    })();

    initData();
    form.submit();
  }, []);

  const initData = async () => {
    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT),
      truckCode: undefined,
      branch: undefined,
      typeTruckRefId: undefined
    });
  }

  const handleSearchAsset = async (data: any) => {
    if (data.length < 3) return;
    const res = await tAssetHeadService.getAssetByNameNumberDriverList(data, userInfo.branchId);
    setAssets(res.data);
  };


 
   const onFinish = async (values: any) => {
    setLoading(true);
    const fromDate = moment(values.fromDate).format("yyyyMMDD");
    const toDate = moment(values.toDate).format("yyyyMMDD");
    const truckCode = values.truckCode ? values.truckCode : "";
    const typeTruckRefId = values.typeTruckRefId ? values.typeTruckRefId : "";
    const branch = values.branch ? values.branch : "";
    
    const res = await reportService.getTruckIncomeReport(
              fromDate, toDate, truckCode, typeTruckRefId, branch);
    // console.log('----------res--------', res.data)
    setsetTruckIncomeReportList(res.data);
    // setLoading(false);

    setPage(1);
    setFromDateParam(fromDate);
    setToDateParam(toDate);
    setTruckCodeParam(truckCode);
    setTypeTruckParam(typeTruckRefId);
    setBranchParam(branch);

    setLoading(false);
    
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const handleChangeAssetGroup = (data: any) => {
    setTTypeTruckRefId(data);
  };

  const onPrint = async () => {
    setVisiblePDF(true);
  }


  return (
    <>
      <PageHeader title="รายงานรายได้รถ"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="rengradWangbill"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    // onChange={form.submit}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={1} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    // onChange={form.submit}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ประเภทรถ: </Typography.Text>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="typeTruckRefId"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    // style={{ width: "250px" }}
                    allowClear
                    size="middle"
                    placeholder="เลือกประเภทรถ"
                    optionFilterProp="children"
                    // onChange={handleChangeAssetGroup}
                    filterOption={true}
                  >
                    {tTypeTruckRefList.map((obj) => {
                      return (
                        <Option value={obj.iid} key={obj.iid}>
                          {obj.iid} - {obj.iname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หมายเลขรถ: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item 
                  name="truckCode" 
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    // style={{ width: 200 }}
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกรถ/อุปกรณ์"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={handleSearchAsset}
                  // onSelect={form.submit}
                  >
                    {assets.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.numbers} : {obj.assetname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>สาขา: </Typography.Text>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="branch"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกสาขา"
                    optionFilterProp="children"
                    filterOption={true}
                    onClear={() => setRyRefBranchSelected({} as RyRefbranch)}
                  >
                    {ryRefBranchs.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.id}: {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
              paddingBottom: "1em",
              // borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
            </Space>
          </div>
        </Form>


           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            // margin: '0 0 10px 0',
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RS001"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            {/* <iframe title=" " src={worksheetDateWorkPreview} width={"100%"} height={"100%"}></iframe> */}
          </Modal>
        </div>

        <div>
          <Table
            columns={columns}
            dataSource={truckIncomeReportList}
            size="small"
            loading={loading}
            rowKey={(item: TruckIncomeReportData) => item.reserveCode!}
            scroll={{ x: 2400 }}
            pagination={{
              pageSize: 10,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
            summary={(pageData: any) => {
              let sumTotal2Amt = 0.0;
              if(truckIncomeReportList.length < 1){
                setSumTotal2Amt(sumTotal2Amt);
              }
              truckIncomeReportList.forEach((item) =>{
                sumTotal2Amt += item.total2Amt;
                setSumTotal2Amt(sumTotal2Amt);
              });
              return (
                <></>
              )
            }}
          />
        </div>

        <div style={{
            padding: "20px 0px 0px 0px"
          }}>
          <Row>
            <Col span={19} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", fontSize: 18 }}> รวมรายได้: </Typography.Text>
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red", fontSize: 18 }}> 
                {formatNumber(sumTotal2Amt)}
              </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left", marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold", fontSize: 18 }}> บาท </Typography.Text>
            </Col>
          </Row>
        </div>
      </Content>

      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          urlPath={reportService.getTruckIncomeReportPreview(fromDateParam, toDateParam, truckCodeParam, typeTruckParam, branchParam)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default TruckIncomeReport